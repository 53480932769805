.show_staff {
    display: none;
}

@media screen and (max-width: 1240px) {
    .hide_staff {
        display: none;
    }
    .show_staff {
        display: flex;
        width: 90%;
    }

}