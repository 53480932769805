.show_mobile_clubs{
    display: none;
}
@media screen and (max-width: 1240px) {
    .hide_clubs {
        display: none;
    }
    .show_mobile_clubs{
        display: flex;
        width: 90%;
    }
    .mobile_clubs_image > img{
        object-fit: fill;
        margin-bottom: 0;
        height: 100%;
    } 
    .mobile_clubs_image {
        margin-bottom: 0;
        height: 15vmin;
    }
    .mobile_clubs_main {
        margin-top: 0;
    }
    .custom_accordion  {
        font-size: 4vmin;
    }
    .custom_accordion > div {
        padding: 4vmin;
        font-size: 3vmin;
    } 
}
