.step-image {
    width: 50px;
    height: 50px;
  }
  
  .step-title {
    color: #37474f;
    font-weight: 700;
  }

@media screen and (max-width: 1240px) {
    .mobile_admission_steps {
        display: flex;
        flex-direction: column;
        row-gap: 5vmin;
    }
    .mobile-admission-button{
      width: 50%;
    }
    .mobile-form{
      min-width: 75vmin;
      margin-left: auto;
      margin-right: auto;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

}