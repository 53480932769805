.contact-map {
    height: auto;
    max-height: 50vh;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3vmin;
  }
  
@media screen and (max-width: 1240px) {
    .mobile-contact-details{
        margin-left: auto;
        margin-right: auto;
        margin-top: 5vmin;
        margin-bottom: 5vmin;
        padding: 0;
        width: 90%;
      }
      .mobile-contact-details p{
        font-size: 3vmin;
        text-align: justify;
      }
      .contact-form{
        margin-left: auto;
        margin-right:auto;
        padding: 0;
        width: 90%;
      }

      .contact-map-and-card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        padding: 0;
      }
    
      .contact-map {
        height: auto;
        width: auto;
        max-width: 80vmin;
        overflow: hidden;
        margin-right: auto;
        margin-left: auto;
        margin-top: 10vmin;
        margin-bottom: 0;
    }
      .contact-card{
        width: 100vmin;
        height: fit-content;
        margin-top: 15vmin;
        margin-bottom: 15vmin;
        padding: 0;
        justify-content: start;
        column-gap: 2vmin;
      }
}


