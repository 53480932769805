html {
  scroll-behavior: smooth;
}

.layer {
  background-color: rgba(7, 6, 6, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home_layer {
  background-color: rgba(7, 6, 6, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.board_layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hover_state:hover {
  background-color: white !important;
  color: #EF6F56 !important;
  border: 2px solid #EF6F56;
}

.leaflet-container {
  height: 300px;
  width: 500px;
  border-radius: 3%;
}

.board_bg {
  background-image: url('./assets/class-board.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.tree {
  background-image: url('./assets/legacy-statue-landscape.jpeg');
  background-size:150%;
  background-position: center;
  background-repeat: no-repeat;
}

.cedarville-cursive-regular {
  font-family: "Cedarville Cursive", cursive;
  font-weight: 400;
  font-style: normal;
}
.cedarville-cursive-bold {
  font-family: "Cedarville Cursive", cursive;
  font-weight: 700;
  font-style: normal;
}


.exo-2 {
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.exo-2-bold {
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: bold;
}

.quicksand {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.quicksand-bold {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}


.playfair-display {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: bold;
}

.sevillana-bold {
  font-family: "Sevillana", cursive;
  font-weight: 700;
  font-style: normal;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.montserrat-bold{
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  /* letter-spacing: 1px; */
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#00ff00;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px #999;
  z-index: 40;
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.my-float{
	margin:10px;
}

.btn{
  border: none;
  color: white;
  background-color: #EF6F56;
  font-weight: bold;
}

.btn:hover{
  color: #EF6F56;
  border: 2px solid #EF6F56;
}

.cutom-border {
  border-color: #EF6F56;
}

.marquee-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 50px; /* Set the desired height */
  white-space: nowrap;
  background-color: #EF6F56; /* Optional: Set background color */
}
.custom-color > div{
  background-color: #EF6F56 !important;
}
.marquee-content {
  display: inline-block;
  padding-left: 100%;
  margin-top: 12px;
  font-weight: bold;
  color: #f5f5f5;
  animation: marquee 30s linear infinite;
}

.header_text {
  font-size: 8vmin;
}

.subhead_text {
  font-size: 5vmin;
}
.mobile_view {
  display: none;
}

.tooltip {
  position: absolute;
  background: #f5f5f5cc; /* Darker background for contrast */
  width: 250px;
  color: #37474f;
  padding: 10px;
  border-radius: 5px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}
.tooltip::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* Size of the arrow */
  border-right: 10px solid transparent;
  border-top: 10px solid #f5f5f5cc; /* Color matching the tooltip background */
  bottom: -10px; /* Positioning the arrow below the tooltip */
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-content {
  z-index: 1;
}

.tooltip.visible {
  opacity: 1;
}

.student_image {
  display: block;
  max-width: 100%;
  border-radius: 10px;
}
.custom_padding {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #37474f;
}
.custom_padding > div:first-child {
  display: flex;
  flex-direction: column;
  width: 30vmin;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 0px;
  color: #37474f;
}

.custom_padding > div:nth-child(2) {
  align-items: flex-start;
  margin-left: 5%;
  width: 100%;
  margin-top: 0px;
}

.custom_padding > button:focus {
  box-shadow: 0px 2px 0px 0px #EF6F56;
  width: 100%;
  border-bottom-width: 2px;
  border-color: #EF6F56;
  color: #EF6F56;
}

.custom_padding > button {
  width: 100%;
  border: none;
  color: #37474f;
  justify-content: flex-start;
}



.text-cyan-600 {
  color: #f5f5f5;
}

.bg-cyan-200 {
  background-color: #EF6F56;
}

.staff_table th {
  color: #EF6F56;
  font-size: 24px;
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: bold;
  border-bottom: 2px solid #EF6F56;
}

.staff_table td {
  color: #37474f;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.hide_tabs {
  display: flex;
}
.show_tabs {
  display: none;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.leaflet-container{
  z-index: 0 !important;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(0px, 10px, 0px);
  }
  20%,
  80% {
    transform: translate3d(0px, 10px, 10px);
  }
  30%,
  50%,
  70% {
    transform: translate3d(0px, 10px, 10px);
  }
  40%,
  60% {
    transform: translate3d(0px, 10px, 10px);
  }
}

.nav-font{
  font-size: 0.875rem;
}

.nav-text:hover{
  color:#EF6F56;
}
/* Add this to your CSS file */
.bg-gradient-mild-princi {
  background: linear-gradient(to top, rgba(255, 255, 255, 1)30%, rgba(14, 116, 144, 0.2)100%);
  
}
.bg-gradient-mild-corres {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1)30%, rgba(14, 116, 144, 0.2)100%);
  
}

.border-check{
  border: 1px solid red;
}

.photo {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.photo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.custom-bullet li::marker {
  color: #0e7490;
}

.facilities .flex{
  justify-content: flex-start;
}

.principalImage {
  background-image: url('./assets/diagonal_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 20px;
}

.numbers {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.number {
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
}

.number .counter {
  font-size: 8vmin;
  /* margin: 6px 0; */
  text-align: center;
  color: #EF6F56;
  font-weight: bold;
}
.number span {
  font-size: 3vmin;
  display: block;
  color: #37474f;
  font-weight: 500;
}


.checkmark {
  color: #0e7490;
  font-size: 24px;
  margin-right: 8px;
}

.crossmark {
  color: red;
  font-size: 24px;
  margin-right: 8px;
}


.mobile_sideBar {
  display: none;
}

@media screen and (max-width: 1240px) {
  .brief_mobile {
    display: flex;
    flex-direction: column;
    gap: 2;
  }
  .short-gallery{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mobile-legacy-content{
    font-size: 3.5vmin;
    text-align: justify;
  }
  .mobile_card_padding > div:first-child {
    padding-top: 0;
    padding-bottom: 0;
  }
  .checkmark_mobile {
    color: #0e7490;
    font-size: 16px;
    margin-right: 8px;
  }
  .crossmark_mobile {
    color: red;
    font-size: 16px;
    margin-right: 8px;
  }
  .content{
    width: 100%;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 3vmin;
    height: auto;
}
.corres-mobile{
  width: 90%;
}
.corres-img-container{
  width: 15rem;
  height: 15rem;
}
.corres-mobile-blue-text{
  font-size: 3vmin;
  width: 80%;
  text-align: center;
}
  .tree {
    height:80vmin;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .legacy {
    width: 90%;
    margin-left:auto;
    margin-right: auto;
  }
  .mobile-inner-timeline{
    width: 80%;
    height: fit-content;
    /* height: auto; */
    /* margin-top: 15vmin; */
  }
  .mobile-timeline-header{
    font-size: 5vmin;
  }
  .mobile-timeline-title{
    font-size: 3vmin;
  }
  .mobile-timeline-date{
    font-size: 2vmin;
  }
  .mobile-timeline-body{
    font-size: 2vmin;
  }
  .mobile-counter{
    padding: 0;
  }
  .mobile_card {
    margin-left: 0;
  }
  .sticky_button {
    display: none;
  }
  .timeline_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 10vmin; */
    /* margin-bottom: 10vmin; */
  }
  .timeline_mobile > div {
    width:90%;
    height: 80vh;
  }
  .review_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .review_mobile > div {
    width: 100%;
    height: 45%;
    margin-top: 20px;
  }
  .normal_view {
    font-size: 2vmin;
  }
  .mobile_star {
    height: 6vmin;
    width: 6vmin;
    /* padding: 1vmin !important; */
    margin-left: 1vmin;
    margin-right: 1vmin;

  }
  .mobile_logo {
    height:35vmin;
    width: 40vmin;
  }
  .header {
    height: 70vh;
  }
  .header_text {
    font-size: 6vmin;
    letter-spacing: 1px;
    margin: 5vmin 2vmin 5vmin 2vmin;
  } 
  .number{
    gap: 0;
  }
  .number .counter {
    font-size: 6vmin;
  }
  .number span {
    font-size: 2.5vmin;
    margin-top: 0;
  }
  .mobile-page-header{
    height:15vmin ;
  }
  .normal_sideBar {
    display: none;
  }
  .mobile_sideBar {
    display: flex;
    flex-direction: column;
  }
  .tooltip{
    display: none;
  }
  .tooltip-container{
    display: none;
  }
  .subhead_text {
    font-size: 4vmin;
  }
  .mobile_clubs_gap {
     gap: 2rem;   
  }
  .mobile-academics{
    width: 90%;
  }

  .mobile-admission{
    width: 90%;
  }
  .contact-us-container {
    position: relative;
    width: 90% !important; /* Adjust width as needed */
    background-color: #EF6F56;
    border: 2px solid #EF6F56;
    border-radius: 8px; /* Optional: rounded corners for the container */
    padding: 20px; /* Optional: padding inside the container */
    box-sizing: border-box;
  }

}

.soft-red{
  color:#EF6F56
}
.soft-red-bg{
  background-color: #EF6F56;
}

.teal{
  color: #0e7490;
}
.teal-bg{
  background-color: #0e7490;
}

.grey{
  color: #f5f5f5;
}
.grey-bg{
  background-color: #f5f5f5;
}

.dark-bluish{
  color: #37474f;
}
.dark-bluish-bg{
  background-color: #37474f;
}

.translate-center {
  transform: translate(-50%, -50%);
}
.shadow-bottom {
  box-shadow: 0 4px 2px #f5f5f5; /* Teal color with opacity */
}

.short-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.contact-us-container {
  position: relative;
  width: 80%; /* Adjust width as needed */
  background-color: #EF6F56;
  border: 2px solid #EF6F56;
  border-radius: 8px; /* Optional: rounded corners for the container */
  padding: 20px; /* Optional: padding inside the container */
  box-sizing: border-box;
}

.circle {
  font-family: "Exo 2", sans-serif;
  position: absolute;
  top: -60px; /* Position the circle so it overlaps the top border */
  left: 50%;
  transform: translateX(-50%);
  width: 120px; /* Diameter of the circle */
  height: 120px;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: none;
  box-shadow:  0 25px 50px -12px rgb(0 0 0 / 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #EF6F56;
  z-index: 1; /* Ensure circle is on top of the container */
}
