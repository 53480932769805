@media screen and (max-width: 1240px) {
    .mobile-section-1{
        display: flex;
        flex-direction: column;
        height: auto;
        
    }
    .principal-container{
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        gap: 5vmin;

    }
    .hide-last-section-mobile{
        display: none;
    }
    .princi-img-container{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        padding: 0 !important;

    }
    .princi-img-container div img{
        width: 100%;
        height: auto;
    }
    .content-container{
        margin-top: 7vmin;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        height: auto;
        padding: 0 !important;
        gap: 3vmin;
    }
    .content-container div h1{
        text-align: center;
        font-size: 4vmin;
        margin-bottom: 2vmin;
    }
    .content-container div h6{
        font-size: 2vmin;
    }
    .mobile-section-2{
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 3vmin;
    }
    /* .card-contents p{
        text-align: center;
        font-size: 3vmin;
    } */
    .card-contents h1{
        font-size: 4vmin;
    }
    .mobile-section-3{
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 5vmin;
    }
    .principal-content{
        width: 100%;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        font-size: 3vmin;
        height: auto;
    }
    .princi-content p{
        font-size: 3vmin;
    }
    .title-container{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        margin-top: 0;
    }
    .title{
        font-size: 4vmin;
        padding-top: 3vmin;
        padding-bottom: 3vmin;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .mobile-princi-cards{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

