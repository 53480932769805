.custom_ring_color > button:focus {
    box-shadow: 0px 2px 0px 0px #EF6F56;
    border-bottom-width: 2px;
    border-color: #EF6F56;
    color: #0e7490
}
  
.custom_ring_color > button {
    color: #EF6F56 
}

.event-list-item > button {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1240px) {

    .mobile-upcoming-events{
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }
    .mobile-archived-events{
        display: flex;
        flex-direction: column;
    }
    .mobile-event-title{
        font-size: 4vmin;
    }
    .mobile-event-description{
        font-size: 3vmin;
    }
    .mobile-gap{
        gap: 2rem;
    }
}
