.hide_tabs {
    display: flex;
  }
  .show_tabs {
    display: none;
  }

  .checkmark {
    color: #0e7490;
    font-size: 24px;
    margin-right: 8px;
  }
  
  .crossmark {
    color: red;
    font-size: 24px;
    margin-right: 8px;
  }

  
@media screen and (max-width: 1240px) {
    .hide_tabs {
        display: none;
      }
      .show_tabs {
        display: flex;
      }
      .mobile_board{
        height: 50vmin;
        width: 90%;
      }
      .board_layer {
        padding: 1.5rem;
      }
      .mobile-width-adjust{
        width: 90%;
      }
      .mobile_board_subtitle {
        font-size: 1.5vmin;
      }
      .mobile_board_title {
        font-size: 3vmin;
      }
      .checkmark {
        color: #0e7490;
        font-size: 2.5vmin;
        margin-right: 8px;
      }
      
      .crossmark {
        color: red;
        font-size: 2.5vmin;
        margin-right: 8px;
      }
    
      .mobile-sponsor-button{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
      }
      .sponsor-btn > span {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .mobile-sponsor-title{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      .mobile-section{
        margin: 0 !important;
      }
}