.mobile_about_school {
    display: none;
}
.about_school {
    display:flex;
}
@media screen and (max-width: 1240px) {
    .about_school {
        display:none;
    }
    .mobile_about_school {
        display: flex;
    }
    /* .mobile_about_image {
        height: auto;
    } */
    .mobile_gap {
        row-gap: 0.5rem;
    }
    .mobile_video_height {
        height: auto;
    }
}